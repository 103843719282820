/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Section from '../Section'
import Button from '../Button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Content = ({ data }) => (
  <Section variant={data.contentfulSection.type}>
    <div
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: ['column-reverse', 'row'],
        justifyContent: 'center',
      }}
    >
      <div
        sx={{
          flex: 1,

          p: {
            mb: 4,
          },
        }}
      >
        {data.contentfulSection.title && <Styled.h1>{data.contentfulSection.title}</Styled.h1>}
        {data.contentfulSection.description && (
          <Styled.p>{data.contentfulSection.description}</Styled.p>
        )}
        {data.contentfulSection.button && (
          <Button to={data.contentfulSection.buttonLink} variant='primary'>
            {data.contentfulSection.button}
          </Button>
        )}
      </div>
      <div>
        <Img
          fluid={data.contentfulSection.image.fluid}
          sx={{
            width: ['300px', '400px'],
            height: ['300px', '400px'],
            mb: [4, 0],
          }}
        />
      </div>
    </div>
  </Section>
)

Content.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Content
