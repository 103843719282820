/** @jsx jsx */
import { jsx } from 'theme-ui'
import Container from '../Container'
import PropTypes from 'prop-types'

const Section = ({ variant = 'transparent', children, ...props }) => (
  <section
    {...props}
    sx={{
      variant: `sections.${variant}`,
      py: [4, 5, 6],
    }}
  >
    <Container>{children}</Container>
  </section>
)

Section.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Section
