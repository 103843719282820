/** @jsx jsx */
import { useState } from 'react'
import { jsx } from 'theme-ui'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Link from 'gatsby-link'
import Navigation from './navigation'
import Container from '../Container'
import Burger from './burger'
import Logo from '../../assets/smarthomeandcinema-logo-light.svg'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <div
      sx={{
        bg: [`darkPurple`, 'transparent'],
        pt: [4],
        position: [null, 'absolute'],
        top: 0,
        width: '100%',
        zIndex: 5,
      }}
    >
      <Container>
        <div
          sx={{
            display: 'flex',
            flexDirection: ['row'],
          }}
        >
          <Link to='/'>
            <img
              src={Logo}
              alt='Smart Home and Cinema Logo'
              sx={{ maxWidth: ['160px', '250px'], flex: [1, 'auto'] }}
            />
          </Link>
          <Burger toggle={toggle} isOpen={isOpen} />
          <Navigation isOpen={isOpen} />
        </div>
      </Container>
    </div>
  )
}

export default Header
