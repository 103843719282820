/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

export default ({ variant = 'default', ...props }) => (
  <Link
    {...props}
    activeClassName='active'
    exact
    sx={{
      variant: `links.${variant}`,
    }}
  />
)

Link.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}
