/** @jsx jsx */
import { jsx } from 'theme-ui'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Link from '../Link'

const StyledLink = styled(Link)`
  position: relative;
`

export default function Navigation({ isOpen }) {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allContentfulService {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <div
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            flex: 1,
            position: ['absolute', 'absolute', 'absolute', 'relative'],
            top: [0, null, null, 0],
            left: [0, null, null, 0],
            right: [0, null, null, 0],
            zIndex: 5,
          }}
        >
          <nav
            role='navigation'
            sx={{
              bg: [`secondary`, null, null, 'transparent'],
              display: [isOpen ? 'flex' : 'none', null, null, 'flex'],
              width: ['100%', null, null, 'auto'],
              p: [isOpen && 3],
            }}
          >
            <ul
              sx={{
                listStyleType: 'none',
                display: 'flex',
                flexDirection: ['column', null, null, 'row'],
                p: 0,
                m: 0,
                flex: [1, null, null, 0],
              }}
            >
              <div
                sx={{
                  position: 'relative',
                  display: 'inline-block',
                  '&:hover': {
                    '> div': {
                      display: 'flex',
                    },
                  },
                }}
              >
                <StyledLink to='/services' variant='nav'>
                  Services
                </StyledLink>
                <div
                  sx={{
                    display: ['flex', null, null, 'none'],
                    flex: [1, null, null, null],
                    position: [null, null, null, 'absolute'],
                    borderRadius: [null, null, null, '8px'],
                    overflow: [null, null, null, 'hidden'],
                    flexDirection: 'column-reverse',
                    minWidth: [null, null, null, '250px'],
                    boxShadow: [null, null, null, '0px 8px 16px 0px rgba(0, 0, 0, 0.2)'],
                    zIndex: 1,
                    top: [null, null, null, '100%'],
                  }}
                >
                  {data.allContentfulService.edges.map(({ node, i }) => {
                    return (
                      <Link
                        to={`/services/${node.slug}`}
                        variant='dropdown'
                        sx={{
                          bg: [null, null, null, '#000'],
                          p: [3],
                          m: 0,
                          ml: [3, null, null, 0],
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            bg: `primary`,
                            color: '#fff',
                            borderBottom: 0,
                          },
                        }}
                      >
                        {node.title}
                      </Link>
                    )
                  })}
                </div>
              </div>
              <li>
                <Link to='/about/' variant='nav'>
                  About
                </Link>
              </li>
              <li>
                <Link to='/gallery/' variant='nav'>
                  Gallery
                </Link>
              </li>
              <li>
                <Link to='/faq/' variant='nav'>
                  FAQs
                </Link>
              </li>
              <li>
                <Link to='/contact/' variant='nav'>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    />
  )
}
