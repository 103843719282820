/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import Section from '../Section'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const FeaturedQuote = ({ data }) => (
  <Section>
    <div
      sx={{
        display: 'flex',
        flexDirection: ['column', 'row'],
      }}
    >
      <div sx={{ mb: [4, 0] }}>
        <Img
          alt='Featured Quote Image'
          sx={{
            height: ['300px', '400px'],
            width: ['100%', '400px'],
            borderRadius: '8px',
            mr: [0, 5],
          }}
          fluid={data.image.fluid}
        />
      </div>
      <div sx={{ flex: 1 }}>
        {data.quote.quote && <Styled.h4>{data.quote.quote}</Styled.h4>}
        <div
          sx={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            mt: 4,
          }}
        >
          <div
            sx={{
              bg: `primary`,
              alignSelf: 'center',
              width: '64px',
              height: '4px',
              mr: '2rem',
            }}
          />
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {data.name && <Styled.h5 sx={{ m: 0, p: 0 }}>{data.name}</Styled.h5>}
            {data.jobTitle && <Styled.p sx={{ m: 0, p: 0 }}>{data.jobTitle}</Styled.p>}
          </div>
        </div>
      </div>
    </div>
  </Section>
)

FeaturedQuote.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FeaturedQuote
