/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Img from 'gatsby-image'

export default ({ data }) => (
  <div
    sx={{
      position: 'relative',
      ':hover': {
        '> div': {
          opacity: 1,
        },
      },
    }}
  >
    <Img
      fluid={data.fluid}
      sx={{
        height: '100%',
        width: '100%',
      }}
    />
    <div
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        bg: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        opacity: 0,
        p: [3, 5],
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: 'all 0.2s ease',
      }}
    >
      <Styled.h4 sx={{ color: 'white', fontSize: [2, 3] }}>{data.title}</Styled.h4>
    </div>
  </div>
)
