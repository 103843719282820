/** @jsx jsx */
import { Link } from 'gatsby'
import { Styled, jsx } from 'theme-ui'
import Img from 'gatsby-image'

export default ({ service }) => (
  <Link
    to={`/services/${service.slug}`}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      bg: 'white',
      textDecoration: 'none',
      transition: 'all 0.2s ease',
      mx: [3, null, null, 0],
      '&:hover': {
        transform: 'translateY(5px)',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1);',
      },
    }}
  >
    <Img alt={service.image.title} fluid={service.image.fluid} sx={{ height: '200px' }} />
    <div
      sx={{
        p: [3, null, null, 4],
      }}
    >
      <Styled.h4 sx={{ mb: 2 }}>{service.title}</Styled.h4>
      <Styled.p sx={{ p: 0, m: 0, lineHeight: '1.75rem' }}>{service.subtitle}</Styled.p>
    </div>
  </Link>
)
