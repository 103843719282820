/** @jsx jsx */
import { jsx } from 'theme-ui'
import { func } from 'prop-types'
import Icon from '../Icon'

const Burger = ({ isOpen, toggle }) => {
  return (
    <div
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        display: ['flex', 'flex', 'flex', 'none'],
        cursor: 'pointer',
        bg: `primary`,
        borderRadius: '50%',
        width: '44px',
        height: '44px',
        textDecoration: 'none',
        position: 'relative',
        zIndex: 6,
        marginLeft: 'auto',
      }}
      onClick={toggle}
    >
      <Icon name={isOpen ? 'close' : 'menu'} color='white' size='24px' />
    </div>
  )
}

Burger.propTypes = {
  toggleTheme: func.isRequired,
}

export default Burger
