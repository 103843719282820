/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Button = ({ variant = 'primary', ...props }) => (
  <Link
    {...props}
    activeClassName='active'
    sx={{
      appearance: 'none',
      textDecoration: 'none',
      display: 'inline-block',
      textAlign: 'center',
      fontSize: ['xs', 'sm', 'lg'],
      fontFamily: 'heading',
      m: 0,
      px: 4,
      py: 3,
      border: 0,
      borderRadius: 30,
      variant: `buttons.${variant}`,
    }}
  />
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
