/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'

const Container = ({ children }) => (
  <div
    sx={{
      maxWidth: '1400px',
      margin: '0 auto',
      flex: 1,
      height: '100%',
      px: [4, null, null, 0],
    }}
  >
    {children}
  </div>
)

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
