/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import Section from '../Section'
import Button from '../Button'

const GetStarted = () => (
  <Section variant='gradient'>
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Styled.h2>Speak to a professional</Styled.h2>
      <Styled.p sx={{ mb: 4 }}>Drop us an e-mail and we'll contact you back.</Styled.p>
      <Button to='/contact'>Get Started</Button>
    </div>
  </Section>
)

export default GetStarted
