/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import PropTypes from 'prop-types'

const Question = ({ data }) => (
  <div
    sx={{
      bg: 'white',
      p: [3, 4],
    }}
  >
    <Styled.h3>{data.question}</Styled.h3>
    <Styled.p
      dangerouslySetInnerHTML={{
        __html: data.answer.childMarkdownRemark.html,
      }}
    />
  </div>
)

Question.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Question
