/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Section from '../Section'
import { Link } from 'gatsby'

import Box from '../Box'
import Button from '../Button'

export default ({ data }) => (
  <Section variant='transparent'>
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginBottom: [4, 5],
        textAlign: 'center',
      }}
    >
      <Styled.h1>What we do</Styled.h1>
      <span>
        From automating your daily routine to state of the art cinema rooms. We have you covered.{' '}
      </span>
    </div>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          'repeat(1, minmax(150px, 1fr))',
          'repeat(2, minmax(150px, 1fr))',
          'repeat(4, minmax(150px, 1fr))',
        ],
        gridAutoRows: '1fr',
        gridGap: 4,
        alignItems: 'stretch',
      }}
    >
      <Link sx={{ textDecoration: 'none', flex: '1', display: 'flex' }} to='/services/home-cinema'>
        <Box title='Home Cinemas' icon='tv' />
      </Link>
      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/home-automation'
      >
        <Box title='Home Automation' icon='build' />
      </Link>
      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/multi-room-av'
      >
        <Box title='Multi-room AV' icon='audiotrack' />
      </Link>

      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/cctv-and-security-systems'
      >
        <Box title='CCTV &amp; Security systems' icon='videocam' />
      </Link>

      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/home-wifi-and-network'
      >
        <Box title='Home Wi-Fi &amp; Network' icon='wifi' />
      </Link>

      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/lighting-control'
      >
        <Box title='Lighting Control' icon='lightbulb' />
      </Link>

      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/golf-simulator'
      >
        <Box title='Golf Simulators' icon='golf_course' />
      </Link>

      <Link
        sx={{ textDecoration: 'none', flex: '1', display: 'flex' }}
        to='/services/cinema-chairs'
      >
        <Box title='Cinema Seating' icon='movie' />
      </Link>
    </div>
    <div
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button to='/services' sx={{ mt: 5 }}>
        View our Services
      </Button>
    </div>
  </Section>
)
