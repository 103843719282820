/** @jsx jsx */
import { jsx } from 'theme-ui'
import Section from '../Section'

import Google from '../../assets/google.png'
import Sony from '../../assets/sony.png'
import Nexus from '../../assets/nexus21.png'
import Origin from '../../assets/origin.png'
import Hdanywhere from '../../assets/hdanywhere.png'

export default ({ data }) => (
  <Section>
    <div
      sx={{
        bg: '#fff',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: ['column', 'row'],
        alignItems: 'center',
        justifyContent: 'space-around',
        p: 4,
        img: {
          maxHeight: ['80px', '48px'],
          mb: [4, 0],
        },
      }}
    >
      <img src={Google} alt='' />
      <img src={Sony} alt='' />
      <img src={Nexus} alt='' />
      <img src={Origin} alt='' />
      <img src={Hdanywhere} alt='' />
    </div>
  </Section>
)
