/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'

const Icon = ({ name, size, color, mr }) => (
  <span sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <i
      className='material-icons'
      sx={{
        color: color ? color : 'white',
        fontSize: size ? size : '2.5rem',
        margin: 0,
        marginRight: mr ? mr : 0,
        padding: 0,
        fontFamily: 'Material Icons',
      }}
    >
      {name}
    </i>
  </span>
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Icon
