import React from 'react'
import { ThemeProvider } from 'theme-ui'
import theme from '../gatsby-plugin-theme-ui'
import Footer from '../components/Footer'
import Header from '../components/Header'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Header />
        {children}
        <Footer />
      </ThemeProvider>
    )
  }
}

export default Template
