/** @jsx jsx */
import React from 'react'
import { Styled, jsx } from 'theme-ui'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'

import Section from '../Section'
import Link from '../Link'
import Content from '../Content'

import Logo from '../../assets/smarthomeandcinema-logo-light.svg'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLogo = styled.img`
  max-width: 160px;
`

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
`

const SocialButton = styled.a`
  display: block;
  margin-right: 1rem;
  text-decoration: none;

  svg {
    width: 48px;
    height: 48px;
  }

  &:hover {
    opacity: 0.9;

    svg {
      fill: white;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export default function Footer() {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          allContentfulService {
            edges {
              node {
                title
                slug
              }
            }
          }
          contentfulSection(slug: { eq: "not-sure-what-you-need" }) {
            title
            type
            description
            button
            buttonLink
            image {
              fluid(maxWidth: 600, maxHeight: 600, resizingBehavior: PAD) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Content data={data} />
          <Section variant='dark'>
            <div
              sx={{
                display: 'flex',
                flexDirection: ['column', 'row'],
              }}
            >
              <Column sx={{ mb: [4, 0] }}>
                <StyledLogo src={Logo} alt='Smart Home and Cinema Logo' />
              </Column>
              <Column sx={{ mb: [3, 0] }}>
                <NavLinks>
                  <Styled.h5>Services</Styled.h5>
                  <div sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    {data.allContentfulService.edges.map(({ node }) => {
                      return (
                        <Link to={`/services/${node.slug}`} variant='footer'>
                          {node.title}
                        </Link>
                      )
                    })}
                  </div>
                </NavLinks>
              </Column>
              <Column sx={{ mb: [3, 0] }}>
                <NavLinks>
                  <Styled.h5>Site</Styled.h5>

                  <Link to='/gallery' variant='footer'>
                    Gallery
                  </Link>
                  <Link to='/about' variant='footer'>
                    About us
                  </Link>
                  <Link to='/faq' variant='footer'>
                    FAQs
                  </Link>
                  <Link to='/contact' variant='footer'>
                    Contact Us
                  </Link>
                </NavLinks>
              </Column>
              <Column sx={{ mb: [3, 0] }}>
                <NavLinks>
                  <Styled.h5>Social</Styled.h5>
                  <SocialLinks>
                    <SocialButton href='https://www.twitter.com/smarthomecinema'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='32'
                        height='32'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              fill: 'white',
                            },
                          }}
                          d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm6.508 13.107c.007.136.009.273.009.406 0 4.167-3.169 8.969-8.965 8.969a8.892 8.892 0 01-4.83-1.417c.245.03.496.042.751.042a6.312 6.312 0 003.914-1.349 3.159 3.159 0 01-2.944-2.186c.472.09.958.07 1.422-.055a3.153 3.153 0 01-2.528-3.09v-.039a3.16 3.16 0 001.428.395 3.15 3.15 0 01-.975-4.21 8.96 8.96 0 006.495 3.295 3.152 3.152 0 015.37-2.875 6.326 6.326 0 002-.765 3.166 3.166 0 01-1.385 1.745 6.331 6.331 0 001.81-.498 6.39 6.39 0 01-1.572 1.632z'
                        ></path>
                      </svg>
                    </SocialButton>
                    <SocialButton href='https://www.instagram.com/smarthomeandcinema'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='32'
                        height='32'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              fill: 'white',
                            },
                          }}
                          d='M21 16a5 5 0 11-10 0c0-.285.03-.563.082-.833H9.333v6.661c0 .464.375.839.839.839H21.83a.838.838 0 00.837-.839v-6.661h-1.749c.052.27.082.548.082.833zm-5 3.333a3.334 3.334 0 10-.001-6.667A3.334 3.334 0 0016 19.333zm4-6.833h1.998a.502.502 0 00.502-.5v-1.998a.502.502 0 00-.502-.502H20a.501.501 0 00-.502.502V12c.002.275.227.5.502.5zM16 0a16 16 0 100 32 16 16 0 000-32zm8.333 22.482a1.857 1.857 0 01-1.851 1.851H9.518a1.857 1.857 0 01-1.851-1.851V9.518c0-1.018.833-1.851 1.851-1.851h12.964c1.018 0 1.851.833 1.851 1.851v12.964z'
                        ></path>
                      </svg>
                    </SocialButton>
                    <SocialButton href='#'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='32'
                        height='32'
                        fill='none'
                        viewBox='0 0 32 32'
                      >
                        <path
                          sx={{
                            fill: `primary`,
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              fill: 'white',
                            },
                          }}
                          d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm3.79 11.057h-2.405c-.285 0-.602.375-.602.873v1.737h3.009l-.455 2.476h-2.554v7.435h-2.838v-7.435H11.37v-2.476h2.575V12.21c0-2.09 1.45-3.788 3.44-3.788h2.405v2.635z'
                        ></path>
                      </svg>
                    </SocialButton>
                  </SocialLinks>
                </NavLinks>
              </Column>
            </div>
          </Section>
        </>
      )}
    />
  )
}
