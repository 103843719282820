/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Container from '../Container'
import Button from '../Button'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Hero = ({ title, subtitle, button, buttonUrl, size, minHeight, image }) => (
  <div
    sx={{
      bg: `darkPurple`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: minHeight ? minHeight : [0, '50vh'],
      py: size ? size : [5, 6],
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    {image && (
      <div
        sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0, opacity: 0.5 }}
      >
        <Img fluid={image} alt='Header Image' sx={{ height: '100%' }} />
      </div>
    )}
    <Container>
      <div
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          textAlign: 'center',
          paddingTop: [0, 5],
          zIndex: 2,
          position: 'relative',
        }}
      >
        {title && (
          <Styled.h1 sx={{ color: 'white', fontSize: [4, 6, 7], mb: 2 }}>{title}</Styled.h1>
        )}
        {subtitle && (
          <span sx={{ color: `lightPurple`, maxWidth: ['100%', '70%'] }}>{subtitle}</span>
        )}
        {button && (
          <div sx={{ marginTop: '2rem' }}>
            <Button to={buttonUrl}>{button}</Button>
          </div>
        )}
      </div>
    </Container>
  </div>
)

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.string,
  buttonUrl: PropTypes.string,
  size: PropTypes.array,
  minHeight: PropTypes.array,
  image: PropTypes.object,
}

export default Hero
