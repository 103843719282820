/** @jsx jsx */
import React from 'react'
import { jsx, Styled } from 'theme-ui'
import Section from '../Section'
import Button from '../Button'
import Image from './Image'

export default ({ data, title, subtitle, service, button, isGallery }) => (
  <Section variant='white'>
    <div sx={{ mb: [4, 5] }}>
      {title && <Styled.h2>{title}</Styled.h2>}
      {subtitle && <Styled.p>{subtitle}</Styled.p>}
    </div>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: [
          'repeat(auto-fill, minmax(250px, 1fr))',
          'repeat(auto-fill, minmax(400px, 1fr))',
        ],
        gridAutoRows: '1fr',
        gridGap: '8px',
        alignItems: 'stretch',
      }}
    >
      {data && (
        <React.Fragment>
          {data.map(({ node }, i) => (
            <React.Fragment key={i}>
              {node.assets.map(image => (
                <Image data={image} />
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </div>
    {data && (
      <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: [4, 5] }}>
        <Button to='/gallery'>View all our work</Button>
      </div>
    )}

    {service && (
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            'repeat(auto-fill, minmax(250px, 1fr))',
            'repeat(auto-fill, minmax(400px, 1fr))',
          ],
          gridAutoRows: '1fr',
          gridGap: '8px',
          alignItems: 'stretch',
          mb: 4,
        }}
      >
        {service.assets.map(image => (
          <Image data={image} />
        ))}
      </div>
    )}
    {button && <Button to='/gallery'>View Gallery</Button>}
  </Section>
)
