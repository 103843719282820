/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Styled } from 'theme-ui'
import Icon from '../Icon'
import PropTypes from 'prop-types'

const Box = ({ variant = 'white', children, title, subtitle, icon, ...props }) => (
  <div
    {...props}
    sx={{
      variant: `box.${variant}`,
      p: [4, 5],
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.2s ease',
      '&:hover': {
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
        transform: 'translateY(5px)',
      },
    }}
  >
    {icon && (
      <div
        sx={{
          alignItems: 'center',
          bg: `primary`,
          borderRadius: '50px',
          display: 'flex',
          height: '100px',
          justifyContent: 'center',
          mb: [3, 4],
          width: '100px',
        }}
      >
        <Icon name={icon} />
      </div>
    )}
    {title && <Styled.h5>{title}</Styled.h5>}
    {subtitle && <Styled.p>{subtitle}</Styled.p>}
  </div>
)

Box.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.string,
}

export default Box
